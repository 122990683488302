import React from 'react';
import { Container, Navbar, Nav, Image, Button } from 'react-bootstrap'
import './App.scss'
import logo from './_images/logo.png'
import { bgFish } from './Home.module.scss';

function App() {
  return (
     <>
      <div className="bg-darkblue border-bottom border-dark">
        <Container>
          <Navbar variant="dark">
            <Navbar.Brand><Image src={logo} width="50" /></Navbar.Brand>

            <Nav>
              <Nav.Link className="text-white">Home</Nav.Link>
            </Nav>
          </Navbar>
        </Container>
      </div>

      <div className={`${bgFish} py-5`}>
        <Container className="text-white py-5 text-shadow">
          <h1 className="display-3 font-weight-normal">
            College Fishing Recruiting<br/>
          </h1>

          <hr className="border-white" />
          <h2>More than a hobby! Get your college education and keep fishing</h2>
          <Button variant="blue" size="lg" className="mt-5" href="mailto:info@levelup-athletics.com">Contact Us</Button>
        </Container>
      </div>

      <div className="bg-black text-white text-center border-top border-white">
        <Container className="py-5">
          <p className="lead">If you want to learn more about Level UP Athletics / College Fishing Recruiting, please email us your name and a short message.</p>

          <Button variant="blue" size="lg" className="my-5" href="mailto:info@levelup-athletics.com">Email Us</Button>

          <p>Don't level out in your academic and athletic plans, Level UP!</p>
          <p>Level UP Athletics, LLC</p>
        </Container>
      </div>

      <div className="bg-darkblue">
        <small>Copyright &copy; 2018 Level UP Athletics, LLC/ College Fishing Recruiting - All Rights Reserved.</small>
      </div>
    </>
  );
}

export default App;
